import React, { useContext } from "react";
import useRouter from "hooks/useRouter";
import logo from "assets/logo_small.svg";
import { sidebarNavigation } from "utils/navigation";
import { classNames } from "utils/misc";
import UserContext from "contexts/UserContext";
import AccountMenu from "components/AccountMenu";

const NarrowSidebar = () => {
  const { path } = useRouter();
  const { 
    eventId, 
    ecommerceExperienceFeatureFlag, 
    eventCreationDisableFeatureFlag,
    eventSubdomainFeatureFlag, 
    currentEventName, 
    reportCount
    } = useContext(UserContext);

    // Log the complete navigation items array
  const navigationItems = sidebarNavigation(
    eventId, 
    ecommerceExperienceFeatureFlag, 
    eventCreationDisableFeatureFlag, 
    eventSubdomainFeatureFlag,
    currentEventName, 
    reportCount
  );
  console.log("Navigation Items:", navigationItems);

  return (
    <nav className="md:fixed flex flex-col">
      <div className="h-full hidden min-h-screen w-[20rem] overflow-y-auto bg-indigo-900 md:block">
        <div className="flex w-full flex-col py-6">
          <div className="flex flex-shrink-0 items-center px-4">
            <a href="/">
              <div className="flex-shrink-0 flex items-center cursor-pointer">
                <img className="h-8 w-auto" src={logo} alt="Concierge" />
              </div>
            </a>
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
            {sidebarNavigation(
              eventId, 
              ecommerceExperienceFeatureFlag, 
              eventCreationDisableFeatureFlag, 
              eventSubdomainFeatureFlag,
              currentEventName, 
              reportCount
              ).map((item) => (
              <span key={item.name}>
                { item.href &&
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      path === item.href || (Array.isArray(item.paths) && item.paths.some(pathSegment => path.includes(pathSegment)))
                        ? 'bg-indigo-800 text-white'
                        : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                      'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                    )}
                    aria-current={path === item.href || (Array.isArray(item.paths) && item.paths.some(pathSegment => path.includes(pathSegment))) ? 'page' : undefined}
                  >
                    { item.icon &&
                      <item.icon
                        className={classNames(
                          path === item.href || (Array.isArray(item.paths) && item.paths.some(pathSegment => path.includes(pathSegment))) ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                          'mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                    }
                    <span>{item.name}</span>
                  </a>
                }
                { !item.href &&
                  <div key={item.name} className="pt-5 pb-2 px-3 font-bold text-sm text-indigo-100">{item.name}</div>
                }
              </span>
            ))}
          </div>
          <AccountMenu />
        </div>
      </div>
    </nav>
  );
};

export default NarrowSidebar;
